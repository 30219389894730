$borderRadius: 8px;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $borderRadius;

  & > * {
    flex: 1;
  }
}

.border {
  border: var(--borderWidth) solid var(--border);
}
.cssFrame,
.texture {
  padding: 6px;
}
.cssFrame {
  background-image: var(--bgGradient);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.8);
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    inset: 6px;
    border-radius: #{$borderRadius - 2px};
    box-shadow: inset 0 1px 2px 1px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.4);
  }
}
.texture {
  background-image: var(--bgImage);
  background-size: var(--bgSize);
}
.cssFrame.texture {
  background-image: var(--bgImage), var(--bgGradient);
}
.glow {
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: var(--bgGradient);
    filter: blur(5px);
    border-radius: $borderRadius;
  }
}
