.vimeoWrapper {
  background: #000;

  & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      & body {
        background: #000;
      }
  }
}