.content {
  flex: 1;
  color: theme('colors.white');
  opacity: 0;
  transition: opacity 200ms ease;
  display: flex;
  height: 100%;
  // overflow: hidden;

  &:hover {
    .image {
      transform: scale(1.05) rotate(0.02deg);
    }
  }

  &.inView {
    opacity: 1;
  }
}

.linkOrClick {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.image {
  flex: 1;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: top;
  transition: transform 400ms ease;

  .top {
    object-position: top center;
  }
}

.header, .footer {
  position: absolute;
  left: 0;
  width: 100%;
  padding: theme('padding.2');
  display: flex;
  gap: theme('gap.1');
  pointer-events: none;
}

.header {
  top: 0;
}

.footer {
  bottom: 0;

  &.gradient {
    background: linear-gradient(transparent, rgba(0,0,0,.6));
  }
}

.chip {
  border-radius: theme('borderRadius.xl');
  height: 26px;
  overflow: unset;
  pointer-events: auto;
}
